import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { Restaurant } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  baseUrl:string ;
  restaurants: Restaurant[];

    constructor(private http: HttpClient) { 
    }

    getRestaurantsByHotel(acronym) {
      let data;
      if(this.restaurants){
        data = this.restaurants;
      }else{
        data = this.http.get<Restaurant[]>('/assets/data/' + acronym + '-restaurants.json');
        data.subscribe(
        data => {
            this.restaurants = data;
          });
      }
      return data
    }


    roomService(): Restaurant[]{
        return this.restaurants.filter(function(restaurant){return restaurant.name=="Room Service"});
    }

}
