import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { HotelInfo } from '../../models';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class HotelInfoService {
  hotelInfos: HotelInfo[];

    constructor(private http: HttpClient) { 
    }

    getById(id) {
      id = parseInt(id);
      return this.hotelInfos.filter(function(hotelInfo) { return hotelInfo.id == id; })[0];
    }

    getHotelInfosByHotel(acronym) {
      let data;
      if(this.hotelInfos){
        data = this.hotelInfos;
      }else{
        data = this.http.get<HotelInfo[]>('/assets/data/' + acronym + '-hotel-infos.json').catch((error:any) => []);
        data.subscribe(
        data => {
            this.hotelInfos = data;
          });
      }
      return data
    }

    findIndexFor(id, hotelInfos) {
      var index = hotelInfos.findIndex(function(hotelInfo) { return hotelInfo.id == id });
      return index
    }


}
