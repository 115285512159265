import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private datepipe: DatePipe
  )
  {}

  public formatDate(date: string, dateType: string): string {
    if (date != null) {
      return this.datepipe.transform(new Date(date), dateType);
    }
  }

  public hoursGreatherThan(date1: Date, date2: Date): boolean {
    return date1.getHours > date2.getHours;
  }

  public dateGreatherThan(date1: Date, date2: Date): boolean {
    return date1.getDate > date2.getDate;
  }
}