import { Injectable } from '@angular/core';
import { Restaurant, Property, Category, Product, Activity, HotelInfo } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CurrentStateService {

    public acronym: string;
    public property: Property;
    public restaurants: Restaurant[];
    public categories: Category[];
    public products: Product[];
    public activityCategories: any[];
    public activities: Activity[];
    public hotelInfoCategories: any[];
    public hotelInfos: HotelInfo[];

    constructor() { 

    }

    public setState(acronym, property, restaurants, categories, 
        products, activityCategories, activities, hotelInfoCategories, hotelInfos) {
      this.acronym = acronym;
      this.property = property;
      this.restaurants = restaurants;
      this.categories = categories;
      this.products = products;
      this.activityCategories = activityCategories;
      this.activities = activities;
      this.hotelInfoCategories = hotelInfoCategories;
      this.hotelInfos = hotelInfos;
    }

}
