import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceiverService {
  baseUrl:string ;

  constructor(@Inject(DOCUMENT) document: any, private httpClient : HttpClient) {
  	this.baseUrl = document.location.hostname + PORT;
  }

    getReceivers(){
        return this.httpClient.get('http://' + this.baseUrl + '/receivers-by-ip');
    } 

    restartReceiver(id){
        return this.httpClient.post('http://' + this.baseUrl + '/receiver/restart-app/' + id,{});
    } 

    rebootReceiver(id){
        return this.httpClient.post('http://' + this.baseUrl + '/receiver/reboot/' + id,{});
    } 
}
