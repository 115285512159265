import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { Property } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  property: Property = null;

  constructor(private http: HttpClient) { 
  }

  getByHotel(acronym) {
    let data;
    if (this.property) {
      data = this.property;
    } else {
      data = this.http.get<Property[]>('/assets/data/' + acronym + '-property.json');
      data.subscribe(data => { this.property = data; });
    }
    return data
  }

}
