import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { Activity } from '../../models';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  activities: Activity[];

    constructor(private http: HttpClient) { 
    }

    getActivitiesByHotel(acronym) {
      let data;
      if(this.activities){
        data = this.activities;
      }else{
        data = this.http.get<Activity[]>('/assets/data/' + acronym + '-activities.json').catch((error:any) => []);
        data.subscribe(
        data => {
            this.activities = data;
          });
      }
      return data
    }

}
