import { Component } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Slider } from './animations/slider';
import { trigger, state, animate, transition, style,query,group,animateChild } from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import { RestaurantService, PropertyService } from './services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[
  [
  	
	trigger('routeAnimations', [
  		transition('* => isLeft',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        left: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ left: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ left: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ left: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('* => isRight',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        right: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ right: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ right: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ right: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('isRight => *',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        right: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ right: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ right: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ right: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('isLeft => *',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        left: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ left: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ left: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ left: '0%'}))
		      ])
		    ]),
		]
  	)  ,	
  	transition('isLeft => isLeft',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        left: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ right: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ left: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ left: '0%'}))
		      ])
		    ]),
		]
  	),
  	 transition('item <=> item', [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ]),
      // Animate the new page in
      query(':enter', [
        animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ])
    ])	


])
 
]  	

]

})
export class AppComponent {
  title = 'Restaurant';
  acronym : string; 
  lang : string = 'es';
  language: string;
  toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  titlei18nKey = 'restaurant';
  isRoomService = false;
  restaurant: string;
  menuTitle: string = 'Home';
  externalLinkTitle: string = '';


  constructor(
  	public restaurantService: RestaurantService,
  	public propertyService: PropertyService,
  	public translate: TranslateService,
  	private route: ActivatedRoute,
    private router: Router) {
//  	  translate.addLangs(['en', 'es']);
//      translate.setDefaultLang('es');
//      translate.use('es');
/*
    translate.onLangChange.subscribe((event) => {
  		this.setTitle(this.titlei18nKey)
    });
*/    
  }


  closeNav(){
  }

  public getToolbarImage() {
	if (this.propertyService !== null && 
			this.propertyService.property !== null && 
			this.propertyService.property.toolbar_image &&
			this.propertyService.property.toolbar_image !== null) {

	    return 'assets/data/' + this.propertyService.property.toolbar_image;
	}
	return 'assets/logo3.png';
  }

  public onRouterOutletActivate(event : any) {
    if(event.route.component.title) {
		this.titlei18nKey = event.route.component.title;

		this.externalLinkTitle = event.route.snapshot.queryParamMap.get('title');

		this.restaurant = event.route.snapshot.paramMap.get('restaurant');
		this.isRoomService = !this.restaurant; 
		if (!this.isRoomService) {
			this.restaurant = this.restaurantService.restaurants.find( res => res.name == this.restaurant ).brand;
		}

		if (this.propertyService.property !== null) {
		    this.menuTitle = this.propertyService.property.name;
		}

		this.setTitle(event.route.component.title);

  		var acronym = event.route.snapshot.paramMap.get('acronym');
  		if(acronym) {
  			this.acronym = acronym;
  		}
    }
  }

  private setTitle(titlei18nKey) {
  	if (titlei18nKey == 'home.title') {
		this.title = this.propertyService.property.name;

	} else if (this.externalLinkTitle) {
		this.title = this.externalLinkTitle;

  	} else {
		if (this.isRoomService) {
			this.translate.get('home.room_service').subscribe((res: string) => {
				this.restaurant = res;
			});
		}
		
		this.translate.get(this.titlei18nKey, { restaurant: this.restaurant }).subscribe((res: string) => {
			this.title = res;
		});
  	}
  }

  public getTitle(){
  	return this.title
  }	

  public getMenuTitle() {
  	return this.menuTitle
  }

  public goToLobby(){
  	this.router.navigate(['/'+this.acronym],{ relativeTo: this.route});
  }

  public getUrl(url){
  	return url.replace(/%20/g, ' ')
  }
  public prepareRoute(outlet) {
  	return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  switchLang(lang: string) {
  	this.translate.use(this.lang);
  }	

  getFlag(lang){
  	let languages = lang;
  	if(!lang){
  		languages = this.lang
  	}
  	if(languages=="en"){
  		return 'assets/icons8-usa-48.png'
  	} else {
  		return 'assets/icons8-mexico-48.png'
  	}
  }

}


  /*
    trigger('routeAnimations', [
    transition('* <=> *', [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),
      ],{optional: true}),
      // Animate the new page in
      query(':enter', [
        animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ],{optional: true})
    ]),
])*/

/*
trigger('routeAnimations', [
  		transition('* => isLeft',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        ['left']: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ ['left']: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ ['left']: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ ['left']: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('* => isRight',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        ['right']: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ ['right']: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ ['right']: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ ['right']: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('isRight => *',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        ['right']: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ ['right']: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ ['right']: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ ['right']: '0%'}))
		      ])
		    ]),
		]
  	),
  	transition('isLeft => *',[
		    query(':enter, :leave', [
		      style({
		        position: 'absolute',
		        top: 0,
		        ['left']: 0,
		        width: '100%'
		      })
		    ], {optional:true}),
		    query(':enter', [
		      style({ ['left']: '-100%'})
		    ]),
		    group([
		      query(':leave', [
		        animate('600ms ease', style({ ['left']: '100%'}))
		      ], {optional:true}),
		      query(':enter', [
		        animate('600ms ease', style({ ['left']: '0%'}))
		      ])
		    ]),
		]
  	)*/