import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { SimpleI18NnObject } from '../../models';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class HotelInfoCategoryService {
  baseUrl:string ;
  categories: SimpleI18NnObject[];

    constructor(private http: HttpClient) { 
    }

    getHotelInfoCategoriesByHotel(acronym) {
      let data;
      if(this.categories){
        data = this.categories;
      }else{
        data = this.http.get<SimpleI18NnObject[]>('/assets/data/' + acronym + '-hotel-info-categories.json').catch((error:any) => []);
        data.subscribe(
        data => {

            this.categories = data;
          });
      }
      return data
    }

}
