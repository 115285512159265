import { Inject,Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../environments/environment';

//Comentado para poder deployar en produccion
/*
@Injectable({
  providedIn: 'root'
})
*/
export class ApiService<T> {

  protected headers: HttpHeaders;
  protected baseUrl: string;

  constructor(
  	@Inject(DOCUMENT) document: any,
    protected http: HttpClient,
    protected endpoint: string
  ) {
    this.baseUrl = document.location.hostname + PORT;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  public create(item: T): Observable<T> {
    return this.http
      .post<T>(`${this.baseUrl}/${this.endpoint}`, item, {headers: this.headers});
  }

  public update(primaryKey: any, item: T): Observable<T> {
    return this.http
      .put<T>(`${this.baseUrl}/${this.endpoint}/${primaryKey}`, item, {headers: this.headers});
  }

	public read(primaryKey: any): Observable<T> {
    return this.http
      .get<T>(`${this.baseUrl}/${this.endpoint}/${primaryKey}`, {headers: this.headers});
  }

  public list(): Observable<T[]> {
    return this.http
      .get<T[]>(`${this.baseUrl}/${this.endpoint}/`, {headers: this.headers});
  }

  public delete(id: number): Observable<string> {
    return this.http
      .delete<string>(`${this.baseUrl}/${this.endpoint}/${id}`, {headers: this.headers});
  }
}