import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { Category } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl:string ;
  categories: Category[];

    constructor(private http: HttpClient) { 
    }

    getCategoriesByHotel(acronym) {
      let data;
      if(this.categories){
        data = this.categories;
      }else{
        data = this.http.get<Category[]>('/assets/data/' + acronym + '-categories.json');
        data.subscribe(
        data => {
            this.categories = data;
          });
      }
      return data
    }

}
