import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { PORT } from '../../../environments/environment';
import { Product } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl:string ;
  products: Product[];


  constructor(@Inject(DOCUMENT) document: any, private http : HttpClient) {
  	this.baseUrl = document.location.hostname + PORT;
  }
  getProductsByHotel(acronym){
    let data;
      if(this.products){
        data = this.products;
      }else{
        data = this.http.get<Product[]>('/assets/data/' + acronym + '-products.json');
        data.subscribe(
        data => {
            this.products = data;
          });
      }
      return data
  }

    getProducts(restaurant): Product[]{
        let products = this.products.filter(function(product){
            return product.restaurant == restaurant});
        //return this.sortData(products);
        return products;
    }
/*
    sortData(products) {
        return products.sort((prodA, prodB) => prodA.id < prodB.id ? -1 : 1);
    }
*/
    getProductsByRestaurantAndCategory(restaurant, categoryName, currentLanguage): Product[]{
        let products = this.products.filter(
            function(product){
               return product.restaurant == restaurant && product.category.languages[currentLanguage]['name'] == categoryName});
        //return this.sortData(products);
        return products;
    }


    getProductById(id, restaurant){
      id = parseInt(id);
      return this.products.filter(function(product){return product.id==id && product.restaurant == restaurant})[0]
    }

    getPrevProductById(id){
      let sub: Function;

      //todo: revisar
      sub = function(a){
        return a - 1
      };
      return this.getByIndex(sub,id)
    }

    getNextProductById(id){
      let add: Function;

      //todo: revisar
      add = function(a){
        return a + 1
      };
      return this.getByIndex(add,id)
    }

    getByIndex(op,id){
      var index = this.products.findIndex(function(product){return product.id==id})
      return this.products[op(index)]
    }

    findIndex(id){
      var index = this.products.findIndex(function(product){return product.id==id})
      return index
    }

    findIndexFor(id, products){
      var index = products.findIndex(function(product){return product.id==id})
      return index
    }

}
