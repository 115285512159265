import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './route-reuse-strategy';
import { PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { 
    path: 'about-us', 
    loadChildren: './about-us/about-us.module#AboutUsModule'
  },
  { 
    path: ':acronym', 
    loadChildren: './home/home.module#HomeModule',
    data: {
      animation: 'isRight',
      breadcrumb: 'Home'
    }

  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:PreloadAllModules})],
  providers: [
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
